const sha1 = require('js-sha1');

const _0x317d = [
	'\x72\x61\x6E\x64\x6F\x6D',
	'\x66\x6C\x6F\x6F\x72',
	'\x79\x65\x61\x72',
	'\x73\x75\x62\x73\x74\x72',
	'\x67\x65\x74\x55\x54\x43\x46\x75\x6C\x6C\x59\x65\x61\x72',
	'\x6D\x6F\x6E\x74\x68',
	'\x67\x65\x74\x55\x54\x43\x4D\x6F\x6E\x74\x68',
	'\x64\x61\x79',
	'\x67\x65\x74\x55\x54\x43\x44\x61\x74\x65',
	'\x68\x6F\x75\x72',
	'\x67\x65\x74\x55\x54\x43\x48\x6F\x75\x72\x73',
	'\x6D\x69\x6E\x75\x74\x65',
	'\x67\x65\x74\x55\x54\x43\x4D\x69\x6E\x75\x74\x65\x73',
	'\x73\x65\x63\x6F\x6E\x64',
	'\x67\x65\x74\x55\x54\x43\x53\x65\x63\x6F\x6E\x64\x73',
	'\x64\x61\x74\x65',
	'\x72\x61\x6E\x64',
	'\x2E',
];

var Token = {
	rand: function(_0xcb23x2 = 2) {
		if (_0xcb23x2 == 3) {
			return String(Math[_0x317d[1]](Math[_0x317d[0]]() * 899) + 100);
		}
		return String(Math[_0x317d[1]](Math[_0x317d[0]]() * 89) + 10);
	},
	date: function(_0xcb23x3, _0xcb23x4) {
		const _0xcb23x5 = new Date();
		if (_0xcb23x3 == _0x317d[2]) {
			return String(
				parseInt(
					_0xcb23x5[_0x317d[4]]()
						.toString()[_0x317d[3]](-2)
				) + _0xcb23x4
			);
		}
		if (_0xcb23x3 == _0x317d[5]) {
			return String(parseInt(_0xcb23x5[_0x317d[6]]()) + 1 + _0xcb23x4);
		}
		if (_0xcb23x3 == _0x317d[7]) {
			return String(parseInt(_0xcb23x5[_0x317d[8]]()) + _0xcb23x4);
		}
		if (_0xcb23x3 == _0x317d[9]) {
			return String(parseInt(_0xcb23x5[_0x317d[10]]()) + _0xcb23x4);
		}
		if (_0xcb23x3 == _0x317d[11]) {
			return String(parseInt(_0xcb23x5[_0x317d[12]]()) + _0xcb23x4);
		}
		if (_0xcb23x3 == _0x317d[13]) {
			return String(parseInt(_0xcb23x5[_0x317d[14]]()) + _0xcb23x4);
		}
	},
	token: function() {
		const _0xcb23x6 = this[_0x317d[15]](_0x317d[2], 30);
		const _0xcb23x7 = this[_0x317d[15]](_0x317d[5], 14);
		const _0xcb23x8 = this[_0x317d[15]](_0x317d[7], 16);
		const _0xcb23x9 = this[_0x317d[15]](_0x317d[9], 45);
		const _0xcb23xa = this[_0x317d[15]](_0x317d[11], 34);
		const _0xcb23xb = this[_0x317d[15]](_0x317d[13], 26);
		const _0xcb23xc = sha1(
			_0xcb23x6 +
				_0xcb23x7 +
				_0xcb23x8 +
				_0xcb23x9 +
				_0xcb23xa +
				_0xcb23xb
		);
		return (
			this[_0x317d[16]](2) +
			_0xcb23x6 +
			this[_0x317d[16]](3) +
			_0xcb23x7 +
			this[_0x317d[16]](2) +
			_0xcb23x8 +
			this[_0x317d[16]](3) +
			_0xcb23x9 +
			this[_0x317d[16]](2) +
			_0xcb23xa +
			this[_0x317d[16]](2) +
			_0xcb23xb +
			_0x317d[17] +
			_0xcb23xc
		);
	},
};

export default Token;
