<template>
  <label for="scan" class="toggleWithin flex flex-col items-center justify-between h-screen w-screen focus-within:border-blue-800 border-8" :class="this.cssClass">
    <h1 class="mt-20 text-4xl text-gray-700">SalivaCHECQR</h1>
    <div class="flex flex-col -mt-24">
      <input @change="check" ref="scanner" class="opacity-0 " id="scan" v-model="sample_id" autofocus />
      <span class="text-3xl onFocus" v-text="message"></span>
      <span class="text-3xl onFocusInvert">Click here</span>
    </div>
    <div>
      <a href="https://conceptomed.com/privacy-policy/" target="_blank" class="text-xs uppercase text-gray-700">Privacy policy</a>
    </div>
  </label>
</template>

<script>
import axios from 'axios';
import Token from '../modules/Token'
export default {
  name: 'Check',
  methods: {
    setMessage(status) {
      if(status == 1) {
        this.message = 'The QR code is valid and active';
        this.cssClass = 'bg-green-400';
      }
      if(status == 0) {
        this.message = 'The QR code has not been registered';
        this.cssClass = 'bg-red-400';
      }
      setTimeout(() => {
        this.message = 'Ready to scan';
        this.cssClass='bg-blue-100'
      }, 5000);
    },
    check() {
      const token = Token.token();
      axios.get(`api/v1/sample/registered?sample_id=${this.sample_id}&api_token=${token}`).then(() => {
        this.setMessage(1)
        this.sample_id='';
      }).catch(() => {
        this.setMessage(0)
        this.sample_id='';
      })
    }
  },
  data() {
    return {
      sample_id:'',
      message:'Ready to scan',
      cssClass:'bg-blue-100',
    }
  }
}
</script>
<style>
  .toggleWithin .onFocus {
    @apply hidden;
  }
  .toggleWithin:focus-within .onFocus {
    @apply block;
  }
  .toggleWithin:focus-within .onFocusInvert {
    @apply hidden;
  }
</style>
