import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import './assets/tailwind.css'


axios.defaults.withCredentials = true;
axios.defaults.baseURL =
	process.env.NODE_ENV == 'development'
		? 'http://localhost:8000/'
		: 'https://api.salivacode.eu/';


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
