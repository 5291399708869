<template>
  <div id="app" class="flex bg-gray-100 h-screen w-screen items-center justify-center">
    <Check/>
  </div>
</template>

<script>
import Check from './components/Check.vue'

export default {
  name: 'App',
  components: {
    Check
  }
}
</script>
